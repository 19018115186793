<template>
  <print-dialog-wrapper :title="''" :showHeader="true">
    <ns-codes-barcodes-print></ns-codes-barcodes-print>
  </print-dialog-wrapper>
</template>

<script>
import PrintDialogWrapper from "../../components/dialog/printDialogWrapper.vue";
import NsCodesBarcodesPrint from "../../components/nsCodes/NsCodesBarcodesPrint.vue";

export default {
  components: { NsCodesBarcodesPrint, PrintDialogWrapper }
};
</script>

<style></style>
