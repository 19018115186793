<template>
  <VDialog
    v-model="visibility"
    max-width="600px"
    @click:outside="showConfirmDialog"
    :persistent="isChanged"
    content-class="cross__dialog"
    :transition="$dialogTransition"
  >
    <VCard :height="$vuetify.breakpoint.smAndDown ? 'auto' : ''">
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <VRow class="ma-1 justify-space-between">
          <span class="font-20" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{
            $t("btn.create")
          }}</span>
        </VRow>
      </VCardTitle>
      <VDivider class="mb-2" />
      <VCardText class="pb-0">
        <div class="text-center" v-if="loading">
          <VProgressCircular :size="50" color="primary" indeterminate></VProgressCircular>
        </div>
        <div class="" v-else>
          <VRow no-gutters>
            <VCol cols="12" class="py-0 mt-3 mb-5">
              <v-autocomplete
                :items="Object.values(products)"
                :item-value="'uuid'"
                :item-text="'name'"
                :label="$t('nsCodes.product')"
                :no-data-text="$t('table.noData')"
                dense
                outlined
                hide-details
                v-model="selectedProduct"
                :menu-props="{ bottom: true, offsetY: true }"
                :error-messages="productErrors"
                color="success"
                item-color="success"
              />
            </VCol>
            <VCol v-if="isOptions" cols="12" class="py-0 mb-5">
                <v-autocomplete
                  :items="Object.values(selectedOptions)"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  :label="$t('nsCodes.option')"
                  :no-data-text="$t('table.noData')"
                  dense
                  outlined
                  hide-details
                  v-model="selectedOption"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="success"
                />
            </VCol>
            <VCol cols="12" class="py-0">
              <VTextField
                dense
                outlined
                :label="$t('nsCodes.range_from')"
                v-model="ns_code.range_from"
                color="success"
                required
                :error-messages="rangeFromErrors"
                @keypress="validationForNSCode($event)"
                @paste="validationForNSCode($event)"
                :maxLength="16"
              ></VTextField>
            </VCol>
            <VCol cols="12" class="py-0">
              <VTextField
                dense
                outlined
                :label="$t('nsCodes.range_to')"
                v-model="ns_code.range_to"
                color="success"
                required
                :error-messages="rangeToErrors"
                @keypress="validationForNSCode($event)"
                @paste="validationForNSCode($event)"
                :maxLength="16"
              ></VTextField>
            </VCol>
            <VCol cols="12" class="py-0">
              <v-select
                :items="itemsIN"
                item-text="cell_details.ns_code"
                item-value="uuid"
                dense
                outlined
                :label="$t('nsCodes.incoming_shipment')"
                v-model="ns_code.uuid_delivery"
                color="success"
                item-color="success"
                :loading="loadingSupply"
                :menu-props="{ bottom: true, offsetY: true }"

              >
                <template v-slot:item="{ item }">
                  #{{ item.number || "--" }} (NS:{{ item.cell_details.ns_code }})
                </template>
                <template v-slot:selection="{ item }">
                  #{{ item.number || "--" }} (NS:{{ item.cell_details.ns_code }})
                </template>
              </v-select>
            </VCol>
            <VCol cols="12" class="py-0">
              <VTextarea
                outlined
                dense
                :label="$t('nsCodes.comment')"
                v-model="ns_code.comment"
                no-resize
                counter
                maxlength="255"
                rows="2"
                color="success"
              ></VTextarea>
            </VCol>
          </VRow>
        </div>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0 ">
          <VCol col="6">
            <VBtn
              dark
              block
              class="text-white text-transform-none br-10 w-100"
              @click="onClose"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.cancel") }}
            </VBtn>
          </VCol>
          <VCol col="6">
            <VBtn
              class="success-bg text-white text-transform-none br-10 w-100"
              block
              @click="onCreate"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.create") }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
  </VDialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import language from "../../mixins/language";
import depotService from "../../services/request/depot/depotService";
import notifications from "../../mixins/notifications";
import user from "../../mixins/user";
import EventBus from "../../events/EventBus";
import {
  uniqueByUUID,
  validationForNumbers,
  validationForNSCode
} from "@/mixins/helpers";
import crmForms from "@/services/request/crm/crmForms";
import supplyService from "@/services/request/supply/supplyService";
import _ from "lodash";

export default {
  name: "CreateNsCodeDialog",
  mixins: [language, notifications, validationMixin, user],
  validations: {
    ns_code: {
      range_from: { required },
      range_to: { required }
    },
    selectedProduct: { required }
  },
  data: () => ({
    itemsIN: [],
    loading: false,
    isChanged: false,
    visibleConfirm: false,
    ns_code: {
      id_state: "",
      uuid: "",
      range_from: "",
      range_to: "",
      comment: ""
    },
    ns_codeCopy: {},
    products: [],
    options: [],
    selectedProduct: "",
    onShowProductList: false,
    selectedOption: "",
    selectedOptions: [],
    product: [],
    loadingSupply: false,
    isOptions: false
  }),
  components: {
    ConfirmLeftDialog
  },
  props: {
    visible: {
      required: true,
      type: Boolean
    }
  },
  watch: {
    selectedProduct: {
      handler() {
        this.products.forEach(item => {
          if (item.uuid == this.selectedProduct && item.options_details) {
            this.isOptions = true;
            this.selectedOptions = item.options_details;
            this.selectedOption = ""
          } else if (item.uuid == this.selectedProduct && !item.options_details) {
            this.isOptions = false;
            this.selectedOption = "00000000-0000-0000-0000-000000000000";
          }
        });
      }
    },
    ns_code: {
      deep: true,
      handler(newVal, oldVal) {
        this.isChanged = !_.isEqual(this.ns_codeCopy, this.ns_code);
      }
    }
  },
  async mounted() {
    this.ns_codeCopy = {...this.ns_code}
    await this.getProducts();
    this.getSupplyList();
  },
  methods: {
    validationForNumbers,
    validationForNSCode,
    async getSupplyList() {
      try {
        this.loadingSupply = true;
         const newItems = await supplyService.getSupplyList({
          id_state:311,
          type: "in"
        });
        newItems.object.forEach(item => {
          if(item.cell_details.ns_code != '0'){
            this.itemsIN.push(item)
          }
        });
        this.loadingSupply = false;
      } catch (e) {
        this.loadingSupply = false;
      }
    },
    async getProducts(params) {
      try {
        this.searchLoading = true;
        const newItems = await depotService.getProductsDescriptions({
          ...params,
          order: "DESC",
          orderby: "time_created"
        });
        this.products.push(...newItems);
        this.products = uniqueByUUID(this.products);
        this.loadMoreDisabled = newItems.length < this.perPage;
        this.searchLoading = false;
      } catch (e) {
        this.searchLoading = false;
        console.log(e);
      }
    },
    showConfirmDialog() {
      if (this.isChanged) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    onClose() {
      this.isChanged = false;
      this.ns_code = [];
      this.selectedProduct = "";
      this.selectedOption = "";
      this.$v.$reset();
      this.visibility = false;
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          if(this.validationForNSCodeClick(this.ns_code.range_from, this.ns_code.range_to)){
          this.loading = true;
          const formData = new FormData();
          formData.append("ns_range_to", this.ns_code.range_to);
          formData.append("ns_range_from", this.ns_code.range_from);
          formData.append("comment", this.ns_code.comment);
          if(this.ns_code.uuid_delivery){
            formData.append("uuid_delivery", this.ns_code.uuid_delivery);
          }
          formData.append("uuid_product", this.selectedProduct);
          formData.append("uuid_option", this.selectedOption);
          await depotService.addNsCode(formData);
          this.loading = false;
          this.$v.$reset();
          this.setSuccessNotification(this.$t("nsCodes.ns_code_was_created"));
          EventBus.$emit("ns-code-created");
          this.visibility = false;
          this.clearNsCode();
        }
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e.error.description);
        }
      }
    },
    clearNsCode() {
      Object.keys(this.ns_code).forEach(key => {
        this.ns_code[key] = "";
      });
      this.selectedProduct = "";
      this.selectedOption = "";
    },
    validationForNSCodeClick(rangeFrom, rangeTo) {
    if(this.isOptions == false){
    if(rangeFrom > rangeTo){
      this.setErrorNotification(this.$t("nsCodes.ns_code_validation_range"));
      return false;
    }
    else if(rangeFrom.length == 16 &&
      rangeTo.length == 16 &&
      rangeFrom.slice(0, 2) == '88' &&
      rangeTo.slice(0, 2) == '88'){
    return true;
    }
    this.setErrorNotification(this.$t("nsCodes.ns_code_validation_digits"));
    return false;
  }
  else{
    if(rangeFrom > rangeTo){
      this.setErrorNotification(this.$t("nsCodes.ns_code_validation_range"));
      return false;
    }
    else if(this.selectedOption == ""){
      this.setErrorNotification(this.$t("nsCodes.ns_code_validation_option"));
      return false;
    }
    else if(rangeFrom.length == 16 &&
      rangeTo.length == 16 &&
      rangeFrom.slice(0, 2) == '88' &&
      rangeTo.slice(0, 2) == '88' &&
      this.selectedOption != ""){
    return true;
    }
    this.setErrorNotification(this.$t("nsCodes.ns_code_validation_digits"));
    return false;
  }
}
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    },
    rangeFromErrors() {
      const errors = [];
      if (!this.$v.ns_code.range_from.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.ns_code.range_from.required && errors.push(this.$t("form.errors.rangeFromRequired"));
      return errors;
    },
    rangeToErrors() {
      const errors = [];
      if (!this.$v.ns_code.range_to.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.ns_code.range_to.required && errors.push(this.$t("form.errors.rangeToRequired"));
      return errors;
    },
    productErrors() {
      const errors = [];
      if (!this.$v.selectedProduct.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.selectedProduct.required && errors.push(this.$t("form.errors.productRequired"));
      return errors;
    }
  }
};
</script>

<style scoped></style>
